<template>
    <div>
        <!-- {{ questions }} -->
        <ek-table
            :items="questions"
            :columns="columnsQuestion"
            @details="goToDetails"
            no_delete
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ new Date(value).toLocaleDateString() }}
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            columnsQuestion: [
                {
                    label: " السؤال ",
                    field: "content"
                },

                {
                    label: " اسم المادة",
                    field: "subjectName"
                },
                {
                    label: " اسم الطالب",
                    field: "studentName"
                },

                {
                    label: " تاريخ ",
                    field: "dateCreated"
                },
                {
                    label: " تفاصيل",
                    field: "details"
                }
            ]
        };
    },
    computed: {
        ...mapState({
            // questionLessonDto: ({ lessons }) => lessons.questionLessonDto,

            questions: ({ questionsAnswerer }) =>
                questionsAnswerer.incommingQuestions
        })
    },
    methods: {
        goToDetails({ row }) {
            this.$router.push(
                `/admin/questionAnswerDashBoard/incommingQuestion/${row.id}`
            );
            console.log(row.id);
            // this.$store.commit("getStudentQuestion", e);
        }
    },
    created() {
        this.$store.dispatch("getAllQuestions");
        this.$store.commit("RESET_QUESTION_LESSONDTO");
    }
};
</script>

<style></style>
